<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('subaccountlist_xq')">基本信息</span>
      <span @click="toUrl('add_subaccountlist_shop')">分账商户信息</span>
      <span id="fzcolor">电子账簿信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">电子账簿信息</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>户名</p>
          </td>
          <td>
            <p>账号</p>
          </td>
          <td>
            <p>银行</p>
          </td>
          <td>
            <p>支行</p>
          </td>
          <td>
            <p>开户地</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-if="AccBooChannel.accountName != null && AccBooChannel.accountName != ''">
          <td style="padding-left: 10px">
            <p>{{ AccBooChannel.accountName }}</p>
          </td>
          <td>
            <p>{{ AccBooChannel.accountNo }}</p>
          </td>
          <td>
            <p>{{ AccBooChannel.accountBankName }}</p>
          </td>
          <td>
            <p>{{ AccBooChannel.accountBranchBankName }}</p>
          </td>
          <td>
            <p>{{ AccBooChannel.accountOpen }}</p>
          </td>
          <td>
            <p>
              <i
                class="lianjie"
                @click="editElectronInfo(AccBooChannel,0)"
              >编辑</i>
              <i
                class="lianjie"
                @click="delElectronInfo(0)"
              >删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6
      v-if="AccBooChannel.accountName == null || AccBooChannel.accountName == ''"
      class="xzjsxx"
      @click="addElectronInfo(0)"
    >
      <span>+</span> 新增电子账簿信息
    </h6>
    <div class="desc">
      <h5 class="jsxx">出金账户信息</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>收款账号</p>
          </td>
          <td>
            <p>户名</p>
          </td>
          <td>
            <p>开户行</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-if="AccBooChannel.receiveAccountNo != null &&  AccBooChannel.receiveAccountNo != ''">
          <td style="padding-left: 10px">
            <p>{{ AccBooChannel.receiveAccountNo }}</p>
          </td>
          <td>
            <p>{{ AccBooChannel.receiveAccountName }}</p>
          </td>
          <td>
            <p>{{ AccBooChannel.receiveAccountBankName }}</p>
          </td>
          <td>
            <p>
              <i
                class="lianjie"
                @click="editElectronInfo(AccBooChannel,1)"
              >编辑</i>
              <i
                class="lianjie"
                @click="delElectronInfo(1)"
              >删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6
      v-if="AccBooChannel.receiveAccountNo == null || AccBooChannel.receiveAccountNo == ''"
      class="xzjsxx"
      @click="addElectronInfo(1)"
    >
      <span>+</span> 新增出金账户信息
    </h6>
    <div class="btn">
        <el-button @click="back" class="back">返回</el-button>
        <!-- <el-button @click="next" class="next" type="primary">完成</el-button> -->
      </div>
    <!-- 电子账簿 -->
    <el-dialog
      :title="editElectronIndex !== null ? '编辑电子账簿信息' : '新增电子账簿信息'"
      :visible.sync="electronInfoShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>户名</div>
          <el-input
            v-model="electronInfoData.accountName"
            maxlength="128"
            placeholder="输入户名"
            class="width100"
            tabindex="1"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>银行</div>
          <el-input
            v-model="electronInfoData.accountBankName"
            maxlength="128"
            placeholder="输入银行"
            class="width100"
            tabindex="5"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>开户地</div>
          <el-input
            v-model="electronInfoData.accountOpen"
            maxlength="2048"
            placeholder="输入开户地"
            class="width100"
            tabindex="7"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>账号</div>
          <el-input
            v-model="electronInfoData.accountNo"
            maxlength="128"
            placeholder="输入账号"
            class="width100"
            tabindex="5"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>支行</div>
          <el-input
            v-model="electronInfoData.accountBranchBankName"
            maxlength="2048"
            placeholder="输入支行"
            class="width100"
            tabindex="7"
          ></el-input>
        </li>
      </ul>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="exitBt"
          @click="electronInfoShow = false"
        >取 消</el-button>
        <el-button
          class="addBt"
          :disabled="buttonFlag"
          @click="sureAddElectronInfo(0)"
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 出金账户信息 -->
    <el-dialog
      :title="editIncomeIndex !== null ? '编辑出金账户信息' : '新增出金账户信息'"
      :visible.sync="electronIncomeShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>收款账号</div>
          <el-input
            v-model="electronInfoData.receiveAccountNo"
            maxlength="128"
            placeholder="输入收款账号"
            class="width100"
            tabindex="1"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>开户行</div>
          <el-input
            v-model="electronInfoData.receiveAccountBankName"
            maxlength="128"
            placeholder="输入开户行"
            class="width100"
            tabindex="5"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>户名</div>
          <el-input
            v-model="electronInfoData.receiveAccountName"
            maxlength="128"
            placeholder="输入户名"
            class="width100"
            tabindex="5"
          ></el-input>
        </li>
      </ul>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="exitBt"
          @click="electronIncomeShow = false"
        >取 消</el-button>
        <el-button
          class="addBt"
          :disabled="buttonFlag"
          @click="sureAddElectronInfo(1)"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getAccountBookChannelDetail ,postAccountBookChannelAdd,delAccountBookChannel } from "@/api/trading/electron.js";
export default {
  data() {
    return {
      editElectronIndex:null,
      electronInfoShow:false,
      AccBooChannel:{},
      electronInfoData:{
        // //电子账簿
        // accountName:"",//户名
        // accountNo:"",//账号
        // accountBankName:"",//银行
        // accountBranchBankName:"",//支行
        // accountOpen:"",//开户地
        // // 出金
        // receiveAccountNo:"",//账号
        // receiveAccountName:"",//出金户名
        // receiveAccountBankName:"",//开户行
        // aesKey: "",
        // channelStatus: "FROZEN", // ACTIVATED（激活）、FROZEN（冻结）
        // id:"",//商户Id
        // mchChannelId:"",//商户号
        // mchChannelName:"",//商户名称
        // mchId: "",
        // mchKey: "",
        // mchPrivateKey: "",//商户RSA私钥
        // mchPublicKey: "",//商户RSA公钥
        // notifyUrl: "",//出金回调地址
        // orgId: "",//所属机构
        // orgPublicKey: "",//机构RSA公钥
      },
      buttonFlag: false, //防止重复提交
      editIncomeIndex:null,
      electronIncomeShow:false,
    };
  },
  created() {
    this.electronInfoData = this.$route.query.data;
    this.getAccountBookDetail(this.$route.query.id);
  },
  methods: {
    //获取电子账簿渠道信息
    getAccountBookDetail(mchId){
      getAccountBookChannelDetail(mchId).then((res)=>{
        if(res){
          if(res.resultData.mchChannel!=null){
            this.AccBooChannel = res.resultData.mchChannel;
          }else{
            this.AccBooChannel = {};
          }
        }       
      })
    },
    // 新增电子账簿
    addElectronInfo(type){
      if(type == 0){
        this.electronInfoData.accountName = "";//户名
        this.electronInfoData.accountNo="";//账号
        this.electronInfoData.accountBankName="";//银行
        this.electronInfoData.accountBranchBankName="";//支行
        this.electronInfoData.accountOpen="";//开户地

        this.editElectronIndex = null;
        this.electronInfoShow = true;
      }else{
        this.electronInfoData.receiveAccountNo = "";//账号
        this.electronInfoData.receiveAccountName="";//出金户名
        this.electronInfoData.receiveAccountBankName="";//开户行
        
        this.editIncomeIndex = null;
        this.electronIncomeShow = true;
      }
    },
    //编辑电子账簿渠道信息
    editElectronInfo(electronInfoData,type){
      if(type == 0){
         this.editElectronIndex = 1;
      }else{
        this.editIncomeIndex = 1;
      }
      console.log(type,"9999999")
      this.electronInfoData = JSON.parse(JSON.stringify(electronInfoData));
      this.oldBookKey = electronInfoData.mchKey;
      if(type == 0){
          this.electronInfoShow = true;
      }else{
          this.electronIncomeShow = true;
      }
      
    },
    //删除电子账簿渠道信息
    delElectronInfo(type){
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.electronInfoData = JSON.parse(JSON.stringify(this.AccBooChannel));
        if(type==0){
          this.electronInfoData.accountName = "";//户名
          this.electronInfoData.accountNo="";//账号
          this.electronInfoData.accountBankName="";//银行
          this.electronInfoData.accountBranchBankName="";//支行
          this.electronInfoData.accountOpen="";//开户地
        }else{
          this.electronInfoData.receiveAccountNo = "";//账号
          this.electronInfoData.receiveAccountName="";//出金户名
          this.electronInfoData.receiveAccountBankName="";//开户行         
        }
      
        let data = JSON.parse(JSON.stringify(this.electronInfoData));

        postAccountBookChannelAdd(this.$route.query.id, data).then((res) => {
          if (res.resultStatus == true) {
            this.getAccountBookDetail(this.$route.query.id);
            this.$message({
                type: "success",
                message: "删除成功!",
            });
          }
        })
      }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确定新增/编辑电子账簿
    sureAddElectronInfo(type){ //type 为0 电子账簿 为1 出金信息
      let data = JSON.parse(JSON.stringify(this.electronInfoData));
      console.log(type);
      if(type==0){
          if (!data.accountName) {
            this.$message.error("户名必填");
            return false;
          }
          if (!data.accountNo) {
            this.$message.error("账号必填");
            return false;
          }
          if (!data.accountBankName) {
            this.$message.error("银行必填");
            return false;
          }      
          if (!data.accountBranchBankName) {
            this.$message.error("支行必填");
            return false;
          }
          if (!data.accountOpen) {
            this.$message.error("开户地必填");
            return false;
          }
      }else{
          if (!data.receiveAccountNo) {
            this.$message.error("账号必填");
            return false;
          }      
          if (!data.receiveAccountName) {
            this.$message.error("户名必填");
            return false;
          }
          if (!data.receiveAccountBankName) {
            this.$message.error("开户行必填");
            return false;
          }        
      }

      if (data.id) {
        if (data.mchKey === this.oldBookKey) {
          delete data.mchKey;
        }
      }      
      this.buttonFlag = true;
      data.id = data.id ? data.id : 0;
      postAccountBookChannelAdd(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
           this.getAccountBookDetail(this.$route.query.id);
          if(type==0){
              this.electronInfoShow = false;
          }else{
              this.electronIncomeShow = false;
          }         
        }
        setTimeout(() => {
            this.buttonFlag = false;
        }, 2000);
      });     
    },
    // 返回
    back() {
      this.$router.go(-1);
    },
    // 跳转页面
    toUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },    
  }
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
.tab1 {
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}
.back:hover {
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  color: #fff;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>